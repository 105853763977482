exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-booking-calle-bolsa-tsx": () => import("./../../../src/pages/booking/calle-bolsa.tsx" /* webpackChunkName: "component---src-pages-booking-calle-bolsa-tsx" */),
  "component---src-pages-booking-uncibay-tsx": () => import("./../../../src/pages/booking/uncibay.tsx" /* webpackChunkName: "component---src-pages-booking-uncibay-tsx" */),
  "component---src-pages-carnes-tsx": () => import("./../../../src/pages/carnes.tsx" /* webpackChunkName: "component---src-pages-carnes-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nosotros-tsx": () => import("./../../../src/pages/nosotros.tsx" /* webpackChunkName: "component---src-pages-nosotros-tsx" */),
  "component---src-pages-privacidad-tsx": () => import("./../../../src/pages/privacidad.tsx" /* webpackChunkName: "component---src-pages-privacidad-tsx" */),
  "component---src-pages-vinoteca-tsx": () => import("./../../../src/pages/vinoteca.tsx" /* webpackChunkName: "component---src-pages-vinoteca-tsx" */)
}

